.back-button {
  position: absolute;
  left: 100px;
}

@media screen and (max-width: 800px) {
  .back-button {
    left: 20px;
  }
}
