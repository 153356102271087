.content-container {
  background: #fff;
  flex-direction: column;
  margin-left: 250px;
  max-width: 100%;
}

.ant-menu {
  display: flex;
  flex-direction: column;
  min-height: 85%;
}

.logo-container {
  padding: 20px;
}

.nav-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-header-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 125px;
}

.nav-header-items > *:hover {
  cursor: pointer;
}

.ant-layout-sider,
.ant-layout-sider-dark {
  background: #f6f6f6 !important;
  z-index: 100 !important;
}

.ant-layout-sider-children {
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ant-layout-sider-children::-webkit-scrollbar {
  display: none;
}

.ant-layout-header {
  background: #fff !important;
}

.ant-menu {
  background: #f6f6f6 !important;
}

.ant-menu-item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-menu-item > svg {
  margin-right: 10px;
}

.ant-menu-item::after {
  border-right-color: #633eff !important;
}

.ant-menu-item-selected {
  background: #eae5ff !important;
  color: #633eff !important;
}

.ant-menu-item-active {
  color: #633eff !important;
}

.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active:active,
.ant-menu-item:focus,
.ant-menu-item:active,
.ant-menu > .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  background: #eae5ff !important;
  color: #633eff !important;
}

.menu-header {
  color: var(--dark-color);
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0px 6px 20px;
  margin-top: 30px;
}

.drop-down {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  padding: 8px 16px;
  width: 90%;
  margin: 10px auto;
}

.drop-down > svg:hover {
  cursor: pointer;
}

.drop-down-text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.drop-down-title {
  color: var(--dark-color);
  font-weight: bold;
}

.drop-down-sub-title {
  color: #a09fa8;
}

.drop-down-text p {
  margin: 5px 0;
}

/* RESPONSIVE */
@media screen and (max-width: 991px) {
  .content-container {
    margin-left: 0px;
  }

  .nav-header {
    padding: 0 25px;
  }

  .nav-header-items {
    width: 100px;
  }

  .nav-header-items > div {
    width: 26px !important;
    height: 26px !important;
    font-size: 14px !important;
  }
}
