.form-outer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.register-form-container {
  background: #fff;
  border: 1px solid #e0e0e0;
  width: 420px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.05);
}

.login-form-container {
  background: #fff;
  border: 1px solid #e0e0e0;
  width: 420px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.05);
}

.contact-sales-form-container {
  padding: 0px 115px 90px 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.contact-sales-form-container > div > .primary-title {
  margin-bottom: 15px;
}

.contact-sales-text-container {
  margin-right: 100px;
  width: 29%;
}

.form-header {
  font-family: var(--secondary-font);
  font-size: 30px;
  color: var(--dark-color);
  font-weight: bold;
  text-align: center;
  padding: 10px 0px;
}

.form-sub-header {
  font-family: var(--primary-font);
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #a09fa8;
  padding: 0 20px;
}

.forgot-password-link {
  font-family: var(--primary-font);
  font-size: 14px;
  color: #a09fa8;
  display: inline-block;
  margin-top: 8px;
}

.forgot-password-link:focus {
  text-decoration: none;
}

.forgot-password-link:hover {
  color: #86858b;
}

.form-btn-container > :first-child {
  margin-bottom: 20px;
}

.name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name-container > .ant-form-item {
  width: 48%;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: var(--primary-color) !important;
}

.ant-checkbox + span {
  color: #656274;
  font-weight: 400;
  font-family: var(--primary-font);
  font-size: 16px;
  line-height: 24px;
}

/* RESPONSIVE */
@media screen and (max-width: 1500px) {
  .contact-sales-text-container {
    width: 40%;
  }
}

@media screen and (max-width: 1200px) {
  .contact-sales-text-container {
    width: 50%;
    margin-right: 50px;
  }
}

@media screen and (max-width: 1000px) {
  .contact-sales-form-container {
    flex-direction: column;
  }

  .contact-sales-text-container {
    width: 420px;
    margin: 80px 0px 20px 0px;
  }

  .contact-sales-form-container > .form-outer-container {
    margin-top: 0px;
  }
}

@media screen and (max-width: 550px) {
  .form-outer-container {
    padding: 0px 20px 100px;
  }
}

@media screen and (max-width: 520px) {
  .contact-sales-form-container {
    padding: 0px 20px 20px;
  }

  .contact-sales-text-container > .primary-title {
    font-size: 30px;
  }
}

@media screen and (max-width: 500px) {
  .contact-sales-form-container > .form-outer-container {
    padding: 0;
  }
  .form-outer-container {
    width: 100%;
  }

  .contact-sales-form-container
    > .form-outer-container
    > .register-form-container {
    width: 100%;
  }

  .contact-sales-text-container {
    width: 100%;
  }

  .name-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .name-container > .ant-form-item {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .register-form-container,
  .login-form-container {
    padding: 30px 15px;
  }
}
