/* LANDING PAGE SECTION */
.landing-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 100px 150px;
}

.landing-section-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 100px;
}

.landing-section-container:nth-of-type(odd) {
  flex-direction: row-reverse;
}

.landing-text-container {
  width: 35%;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 80px;
}

.landing-title-text {
  color: var(--dark-color);
  font-family: var(--primary-font);
  font-size: 24px;
  align-self: flex-start;
  line-height: 45px;
}

.landing-sub-text {
  color: #656274;
  font-family: var(--primary-font);
  font-size: 20px;
  line-height: 30px;
  text-align: left;
}

.landing-section-img {
  /* height: 80%; */
  height: auto;
}

.landing-main-img {
  height: auto;
  width: 50vw;
}

/* LANDING PAGE */
.landing-referred-user-text-container {
  width: 40%;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 60px;
}

.referred-user-title {
  font-size: 46px;
  line-height: 40px;
  margin-bottom: 25px;
  font-family: var(--secondary-font);
  color: var(--dark-color);
  font-weight: 500;
}

.referred-user-subtitle {
  font-size: 24px;
  line-height: 25px;
  margin-bottom: 25px;
  font-family: var(--primary-font);
  color: var(--dark-color);
  font-weight: 400;
}

.landing-user-code {
  font-size: 24px;
  margin-bottom: 25px;
  font-family: var(--primary-font);
  color: var(--dark-color);
  font-weight: 500;
}

.landing-primary-title {
  font-size: 26px;
  font-family: var(--primary-font);
  color: var(--dark-color);
  line-height: 32px;
  text-align: center;
}

.landing-primary-title:nth-of-type(1) {
  margin-bottom: 45px;
}

.landing-img {
  display: block;
  width: 90%;
  padding: 0px 100px;
  margin: 0px auto 85px;
}

.landing-footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 100px 0px 0px 0px;
  padding-bottom: 115px;
}

.landing-footer-content,
.landing-secondary-title {
  margin-bottom: 30px;
}

.landing-secondary-title {
  font-size: 26px;
  font-family: var(--primary-font);
  color: var(--dark-color);
  font-weight: 500;
  line-height: 30px;
}

/* RESPONSIVE */
@media screen and (max-width: 1520px) {
  .landing-section-container {
    padding: 80px;
  }

  .landing-referred-user-text-container {
    width: 70%;
    margin: 0px 50px 0px 0px;
  }

  .referred-user-title {
    font-size: 36px;
  }

  .referred-user-subtitle,
  .landing-user-code {
    font-size: 20px;
  }

  .landing-secondary-title {
    font-size: 24px;
  }

  .landing-img {
    width: 100%;
    padding: 0 80px;
  }

  .landing-section-img {
    /* height: 400px; */
    width: 40%;
  }

  .landing-text-container {
    width: 55%;
  }
}

@media screen and (max-width: 1200px) {
  .landing-main-container {
    padding: 0px 0px 100px 70px;
  }

  .landing-section-container {
    padding: 60px 40px;
  }

  .landing-primary-title,
  .landing-secondary-title {
    font-size: 24px;
  }

  .landing-img {
    width: 100%;
    /* height: 500px; */
    padding: 0 40px;
  }

  .landing-text-container {
    margin: 0 40px;
  }
}

@media screen and (max-width: 1000px) {
  .referred-user-title {
    font-size: 28px;
  }

  .landing-primary-title,
  .landing-secondary-title {
    font-size: 22px;
  }

  .referred-user-subtitle,
  .landing-title-text,
  .landing-sub-text,
  .landing-user-code {
    font-size: 18px;
  }
}

@media screen and (max-width: 950px) {
  .landing-main-container {
    padding: 0px 0px 100px 40px;
  }

  .landing-img {
    padding: 0 20px;
  }

  .landing-section-container {
    padding: 60px 20px;
  }

  .landing-section-img {
    /* height: 350px; */
    height: 45%;
  }
}

/* @media screen and (max-width: 835px) {} */

@media screen and (max-width: 760px) {
  .landing-main-container,
  .landing-section-container,
  .landing-section-container:nth-of-type(odd) {
    flex-direction: column;
    padding: 0;
  }

  .landing-referred-user-text-container {
    margin: 0;
  }

  .landing-referred-user-text-container,
  .landing-text-container {
    width: 100%;
    padding: 40px 20px;
  }

  .landing-main-img {
    width: 100%;
    /* height: 550px; */
  }

  .landing-section-img {
    width: 100%;
    /* height: 500px; */
  }

  .landing-img {
    padding: 0;
    margin: 0;
  }

  .landing-primary-title:nth-of-type(1) {
    margin-bottom: 0px;
  }

  .landing-primary-title:nth-of-type(2) {
    padding-bottom: 0px;
  }

  .landing-primary-title {
    padding: 40px 15px;
    margin: 0;
    font-size: 22px;
  }

  .promo-buttons-container {
    width: 100%;
    flex-direction: column;
  }

  .promo-buttons-container .ant-btn {
    width: 100%;
  }

  .promo-buttons-container > .ant-btn.ant-btn-large {
    margin-right: 0;
  }

  .promo-buttons-container > .ant-btn:not(:first-of-type),
  .promo-buttons-container > .ant-btn:not(:nth-of-type(2)) {
    margin: 0px 0px 10px 0px !important;
  }

  .landing-footer-content {
    padding: 40px 15px;
    margin: 0;
  }
}

@media screen and (max-width: 400px) {
  .landing-img {
    /* height: 250px; */
  }

  .landing-section-img {
    /* height: 400px; */
  }
}
