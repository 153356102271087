.dashboard-container {
  padding: 100px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: auto;
  width: 80%;
  box-sizing: border-box;
}

.dashboard-contents {
  max-width: 950px;
}

.dashboard-profile-text-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 25px;
}

.dashboard-text-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 50px;
}

.dashboard-user-name-text {
  font-family: var(--primary-font);
  color: var(--grey-color);
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
}

.avatar-container > * {
  width: 60px !important;
  height: 60px !important;
  font-size: 24px !important;
}

.dashboard-title-text {
  font-size: 20px;
  font-family: var(--primary-font);
  font-weight: 500;
  color: var(--dark-color);
}

.empty-text {
  font-size: 14px;
  font-family: var(--primary-font);
  font-weight: 400;
  color: var(--grey-color);
  margin: 10px 0px 38px;
  line-height: normal;
}

.video-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 35px;
  max-width: 100%;
}

.video-cards-container {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.video-card {
  width: 200px;
}

@media screen and (max-width: 1400px) {
  .video-cards-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .dashboard-container {
    width: 100%;
    padding: 100px 20px;
  }

  .avatar-container svg {
    width: 45px;
    height: 45px;
  }
}

@media screen and (max-width: 700px) {
  .video-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .dashboard-container {
    width: 80%;
    padding: 100px 20px;
  }
}

@media screen and (max-width: 550px) {
  .dashboard-profile-text-container > .primary-title {
    font-size: 24px;
  }
}

@media screen and (max-width: 480px) {
  .dashboard-container {
    width: 100%;
  }

  .dashboard-contents {
    width: 100%;
  }
}

@media screen and (max-width: 390px) {
  .dashboard-container {
    padding: 100px 10px;
  }

  .video-cards-container {
    grid-gap: 15px;
  }
}

@media screen and (max-width: 350px) {
  .video-cards-container {
    grid-template-columns: 1fr;
  }
  .dashboard-container {
    padding: 100px 30px;
  }
}
