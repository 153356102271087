.video-card-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e5e2;
  width: 225px;
  border-radius: 8px;
}

.video-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.video-description {
  color: var(--dark-color);
  font-family: var(--secondary-font);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 0px 10px 10px;
}

.klip-video-img {
  height: auto;
}

.uploaded-time-text {
  color: #a09fa8;
  font-size: 12px;
  line-height: 20px;
  font-family: var(--secondary-font);
  font-weight: 400;
}

.video-card-header,
.uploaded-time-text {
  padding: 10px;
}

.video-card-header
  > .ant-space
  > .ant-space-item
  > .ant-dropdown-button
  > .ant-btn-icon-only {
  border: none;
}

@media screen and (max-width: 580px) {
  .video-card-container {
    width: 180px;
  }
}

@media screen and (max-width: 390px) {
  .video-card-container {
    width: 170px;
  }
}

@media screen and (max-width: 330px) {
  .video-card-container {
    width: 100%;
  }
}
